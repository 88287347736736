.llms-work {
    width: 100%;
    background-color: #000;
    color: #fff;
  }
  
  .section-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.6s ease-out;
  }
  
  .section-container.fade-in {
    opacity: 1;
  }
  
  .content {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
  }
  
  .text-content, .image-content {
    flex: 1;
  }
  
  .text-content {
    padding-right: 2rem;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  @media screen and (max-width: 768px) {
    .section-container {
      height: auto;
      min-height: 100vh;
      padding: 2rem 0;
    }
  
    .content {
      flex-direction: column-reverse;
      padding: 0 1rem;
    }
  
    .text-content, .image-content {
      width: 100%;
      padding: 1rem 0;
    }
  
    .text-content {
      order: 2;
    }
  
    .image-content {
      order: 1;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    p {
      font-size: 1rem;
    }
  }

  .section-container {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .section-container.fade-in {
    opacity: 0;
  }
  
  .section-container.fade-in.visible {
    opacity: 1;
  }