.footer {
    width: 100%;
    padding: 6rem 0;
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
  }
  
  .footer-container {
    max-width: 1140px;
    margin: auto;
  }
  
  .footer p {
    font-size: 1.2rem;
    padding: 1rem 0;
  }
  
  @media screen and (max-width: 640px) {
    .footer p {
      font-size: 1rem;
    }
  }