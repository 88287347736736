.hero {
    position: relative;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;
}

.hero::before {
    content: '';
    background-image: url('../assets/f117.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero .content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero .content h1 {
    font-size: 4rem;
    color: #fff;
    margin-bottom: 0.5rem;
}

.hero .content p {
    font-size: 1.8rem;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 2rem;
    color: #fff;
}

.hero .content .button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hero .content .btn {
    margin: 0.5rem;
    padding: 10px 20px;
    background-color: #44444445;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    z-index: 2;
    position: relative;
    display: inline-block;
    font-size: 1rem;
    text-align: center;
    line-height: 1.5;
    transition: background-color 0.3s ease;
}

.hero .content .btn:hover {
    background-color: #d820209a;
}

@media screen and (max-width: 640px) {
    .hero .content h1 {
        font-size: 3rem;
    }

    .hero .content p {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
    }

    .hero .content .btn {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
}