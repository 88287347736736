@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@100;300;400;500;600;800;800&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1, p, a{
color: #fff;
}

body {
  margin: 0;
  font-family: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000000;
}

ul {
  list-style-type: none;
}

a{
  text-decoration: none; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn{
  padding: 12px 32px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}

.btn-light{
  background: rgba(255,255,255, .5);
}

.btn:hover {
  background: rgba(183, 17, 17, 0.5)
}